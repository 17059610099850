<template>
  <b-card>
    <b-button @click="createRoom">Create room</b-button>
  </b-card>
</template>
<script>
import axios from 'axios'
export default {
  props: ["settings"],
  data() {
    return {
      body: {
        startDate: this.settings.interactive_livestream.startDate,
        endDate: this.settings.interactive_livestream.endDate,
        fields: "https://test.tribespot.be",
      },
      roomUrl: "",
    };
  },
  methods: {
    createRoom() {
      console.log(this.body) 
      axios.post(process.env.VUE_APP_API_URL + '/whereby.php', this.body, {
        headers: { "Access-Control-Allow-Origin": "*" },
      })
      .then((response) => {
        console.log(response)
        console.log(response.data)
        this.roomUrl = response.data.roomUrl
      }, (error) => {
        console.log(error)
      })
    },
  },
};
</script>
<style>
</style>
